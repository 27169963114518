// extracted by mini-css-extract-plugin
export var article = "PostTemplate-module--article--lA-wi";
export var title = "PostTemplate-module--title--MabK2";
export var meta = "PostTemplate-module--meta--NbOLC";
export var iconExternal = "PostTemplate-module--iconExternal--WzC1r";
export var separatorTitle = "PostTemplate-module--separatorTitle--2frDv";
export var separator = "PostTemplate-module--separator--6lguU";
export var content = "PostTemplate-module--content--7-lsW";
export var otherPosts = "PostTemplate-module--otherPosts--EG5o9";
export var prev = "PostTemplate-module--prev--0vXPu";
export var next = "PostTemplate-module--next--h8arU";
export var label = "PostTemplate-module--label--xGjSh";
export var name = "PostTemplate-module--name---aQzc";