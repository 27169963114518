import React from 'react';
import { Link, graphql } from 'gatsby';
import { RiExternalLinkLine } from 'react-icons/ri';

import DateTime from 'src/components/DateTime';
import ExternalLink from 'src/components/ExternalLink';
import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import URLs from 'src/utils/urls';
import * as styles from './PostTemplate.module.sass';


const PostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const { title, description, date, external } = post.frontmatter;
  const { previous, next } = pageContext

  return (
    <Layout location={location}>
      <SEO
        title={title}
        description={description || post.excerpt}
        location={location}
      />

      <main>
        <article id="post" className={styles.article}>
          <header>
            <h1 className={styles.title}>
              {title}
            </h1>

            <div className={styles.meta}>
              <DateTime date={date.published} format="long" />

              {date.updated && (
                <span>
                  Updated: <DateTime date={date.updated} format="long" />
                </span>
              )}

              {external && external.url && (
                <span>
                  <ExternalLink to={external.url}>
                    Open Article
                    <RiExternalLinkLine className={styles.iconExternal} />
                  </ExternalLink>
                </span>
              )}
            </div>
          </header>

          <hr className={styles.separatorTitle} />

          <section
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          <hr className={styles.separator} />
        </article>
      </main>

      <OtherPosts
        previous={previous}
        next={next}
      />
    </Layout>
  )
};


const OtherPosts = ({ next, previous }) => {
  return (
    <nav>
      <ul className={styles.otherPosts}>
        <li className={styles.prev}>
          {previous && (
            <Link to={URLs.postPath(previous.fields.slug)} rel="prev">
              <span className={styles.label}>← Previous</span>
              <span className={styles.name}>
                {previous.frontmatter.title}
              </span>
            </Link>
          )}
        </li>

        <li className={styles.next}>
          {next && (
            <Link to={URLs.postPath(next.fields.slug)} rel="next">
              <span className={styles.label}>Next →</span>

              <span className={styles.name}>
                {next.frontmatter.title}
              </span>
            </Link>
          )}
        </li>
      </ul>
    </nav>
  );
};


export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug }, type: { eq: "blog" } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        date { published, updated }
        external { url }
      }
    }
  }
`;


export default PostTemplate;
